import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "index",
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  methods: {
    onSkip(type) {
      if (type == 'tel') {
        open("https://t.me/SillyMatchOfficial", '_target');
      } else if (type == 'x') {
        open("https://x.com/SillyMatch", '_target');
      }
    }
  },
  mounted() {
    this.setWowAnim();
  }
};