var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "index-main"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "logo wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.3s"
    }
  }), _c('div', {
    staticClass: "share wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.3s"
    }
  }, [_c('div', {
    staticClass: "share-item t",
    on: {
      "click": function ($event) {
        return _vm.onSkip('tel');
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1), _c('div', {
    staticClass: "share-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('x');
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-lfg"
    }
  })], 1)])]), _c('div', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "title wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.3s"
    }
  }), _vm._m(0), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn-start wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }), _c('div', {
    staticClass: "btn-learn wow fadeInUp",
    attrs: {
      "data-wow-duration": "1s"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('tel');
      }
    }
  })])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "sub"
  }, [_c('span', {
    staticClass: "wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v("Match Your Way to Power")]), _c('span', {
    staticClass: "wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.6s"
    }
  }, [_vm._v("-Conquer the TON Game World!")])]);
}];
export { render, staticRenderFns };