import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "layout",
  inject: ["reload"],
  data() {
    return {
      unsubscribe: null
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  watch: {},
  methods: {},
  mounted() {}
};