import Vue from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import store from "./store";
import "./assets/css/reset.scss";
import "./assets/css/common.scss";
import "./assets/css/anim.scss";
import "./assets/icons"; //svg图标
import "./assets/js/rem";
import "./assets/fonts/font.css";
import Axios from "./http/index";
import router from "./router";
import Toasted from "vue-toasted";
import directive from "./directive";
import formatter from "./utils/formatter";
import loadConfig from "@tonWalletConfig/config";
import WebApp from "@twa-dev/sdk";
import { anim } from "./mixins/anim.js";
import { WOW } from "wowjs";
import "animate.css";

window._v = Vue.prototype;
_v.screenWidth = window.innerWidth;
_v.screenHeight = window.innerHeight;
WebApp.ready();
WebApp.expand();
Axios.install(Vue);

const options = {
  duration: 3000,
  position: "top-center",
  fullWidth: false,
};
Vue.use(Toasted, options);

_v.WebApp = WebApp;
for (let func in formatter) {
  _v[func] = formatter[func];
}
for (let func in formatter) {
  _v[func] = formatter[func];
}

Vue.use(directive);
for (let func in formatter) {
  Vue.filter(func, formatter[func]);
}
_v.formatter = formatter;

Vue.config.productionTip = false;

_v.registerBodyClick = (isRegister, callback) => {
  isRegister ? document.body.addEventListener("click", callback) : document.body.removeEventListener("click", callback);
};

Vue.mixin(anim);

_v.$wow = WOW;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

