import axios from "axios";
import Vue from "vue";
import loadConfig from "@tonWalletConfig/config";
import store from "@store/index";
import qs from "qs";
import cookie from "js-cookie";

const Axios = axios.create({
  timeout: 30000, // 请求超时时间
});

Axios.defaults.baseURL = loadConfig.http_host;
// 添加请求拦截器
Axios.interceptors.request.use(
  (config) => {
    if (config.useInterceptor === "request") {
      let token = store.state["user"]["token"] || "";
      if (token) config.data["_token"] = token;
      config.maxBodyLength = Infinity;
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
//添加加密拦截器
Axios.interceptors.request.use(
  (config) => {
    if (config.useInterceptor === "encRequest") {
      const expireTime = 60;
      if (!config.params) return config;
      let token = store.state["user"]["token"] || "";
      if (!token) return config;
      config.params = {
        ...config.params,
        expire: now + expireTime,
      };
      let r = token.split("_")[2].split("-");
      r[0] = r[0].padStart(64, r[1]);
      console.log("r", r);
      const cipher = crypto.createCipheriv("aes-256-cbc", Buffer.from(r[0], "hex"), Buffer.from(r[1], "hex"));
      const jsonString = JSON.stringify(data);
      let encrypted = cipher.update(jsonString, "utf8", "base64");
      encrypted += cipher.final("base64");
      console.log("encrypted", encrypted);
      data = { data: encrypted, _token: token };
      console.log("data", data);

      config.method = "post";
      config.maxBodyLength = Infinity;
      config.headers["Content-Type"] = "application/json";
      return config;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/* 响应拦截器 */
Axios.defaults.validateStatus = (status) => {
  // 根据状态码返回结果
  if (/^(2|3)\d{2}$/.test(status)) {
    return true;
  } else {
    _v.$toasted.clear();
    _v.$toasted.error(`Error: ${status} error`);
  }
};
// 添加响应拦截器
Axios.interceptors.response.use(
  (res) => {
    // 对响应数据做点什么
    return res.data;
  },
  (err) => {
    _v.$toasted.clear();
    _v.$toasted.error(`${Vue.prototype.$lang("network error")}`);
    return Promise.resolve(null);
  }
);

// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
  install: (Vue) =>
    Object.defineProperty(Vue.prototype, "$http", {
      value: Axios,
    }),
  Axios,
};
