var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_vm.isRouterAlive ? [_c('layout')] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };